.App {
  height: 100vh;
}

.sl-elements-api {
  height: 100vh;
}

div[title='Schemas'] {
  display: none;
}

a[href^="https://stoplight.io/?utm_source=elements-dev-portal"]
{
  display: none;
}
