body {
  margin: 0;
}

:root {
  --font-ui: Lato,sans-serif;
  --font-prose: Lato,sans-serif;
}

body, * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code, .sl-code-viewer {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

main {
  flex: 1 0 0;
  overflow: hidden;
}

button.sl-border-transparent {
 border-color: var(--color-border-input) !important;
}

.topnav {
  height: 60px;
  display: flex;
  align-items: center;
}

.sl-sticky .sl-bg-canvas-100 {
  background-color: #1A202C;
  color: white;
}

.sl-sticky.sl-bg-canvas-100 {
  background-color: #1A202C;
  color: white;
}

.sl-elements-api > .sl-border-r {
  width: 300px !important;
  padding-left: 0 !important;
}

.sl-elements-api .sl-my-3 {
  padding-bottom: 50px;
}

.topnav__sidebar {
  position: relative;
  width: 300px;
  min-width: 300px;
  /* background-color: var(--color-canvas-100); */
  color: white;
  background-color: #1A202C;
  border-right: 1px solid rgba(181, 194, 217, 0.5);
  height: 100%;
  display: flex;
  align-items: center;
  overflow: visible;
  z-index: 1;
}

.topnav__sidebar__title {
  font-size: 14px;
  padding-left: 8px;
}

.topnav__sidebar__link {
  position: absolute;
  display: flex;
  left: 16px;
  top: 70px;
  align-items: center;
  font-size: 12px;
  color: white;
  opacity: 0.8;
  z-index: 1;
}

.topnav__sidebar__link svg {
  margin-right: 6px;
}


.home {
  padding-left: 16px;
}

.topnav__search {
  border-bottom: 1px solid rgba(181, 194, 217, 0.5);
  width: calc(100% - 300px);
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 40px;
  font-size: 14px;
  color: rgba(38, 38, 38, 0.4);
}

.sl-prose.sl-markdown-viewer {
  overflow: visible;
}

.topnav__search span {
  padding: 0 16px;
}

.sl-bg-canvas-100 .sl-text-sm.sl-leading-relaxed.sl-font-bold.sl-uppercase {
  border-bottom: 1px solid rgba(181, 194, 217, 0.5);
  /* border-top: 1px solid rgba(181, 194, 217, 0.5); */
  margin-bottom: 0;
  padding-bottom: 6px;
}

.sl-flex.sl-flex-col.sl-max-h-full.sl-bg-canvas-100 {
  height: 100%;
}

.sl-bg-canvas .sl-py-16 {
  /* max-width: 960px !important; */
}

.sl-flex-1.sl-items-center.sl-truncate {
  opacity: 0.8;
}

.sl-sticky .svg-inline--fa {
  color: white;
}

.sl-overflow-y-auto.sl-w-full.sl-bg-canvas-100 {
  padding-bottom: 25px;
}

.ElementsTableOfContentsItem > div:hover {
  background-color: var(--color-primary-tint);
}

.sl-select-none:hover {
  background-color: var(--color-primary-tint);
}

@media print {
  .topnav {
    display: none;
  }

  .sl-elements-api > .sl-bg-canvas-100 {
    display: none;
  }

  .sl-markdown-viewer-toc {
    display: none;
  }

  .sl-overflow-y-auto.sl-flex-1.sl-w-full.sl-px-24.sl-bg-canvas {
    overflow: initial !important;
  }

  main {
    overflow: initial;
  }
}
